<template>
  <div class='view'>
    <top-nav @menu_data="getMenuData" />
    <div class="blank70"></div>
    <div class="pricings">
      <div class="titles">
        {{ $t("pricing.choose_the_plan") }}
      </div>
      <el-tabs type="border-card" class="tabsstyle" v-model="value"> 
        <el-tab-pane :label="$t('pricing.monthly')" name="first">
          <div class="pricings_items" v-for="(d,i) in pricelist" :key="i">
            <div class="price_tab" :class="{'price_tab_background':d.ishot}" :style="d.topStyle">
              <div class="main_title">
                <p class="pricings_items_title">{{d.title}}</p>
                <p class="contents_item_object" :class="[isID?'IDcontents_item_object':'']">{{d.object}}</p>
              </div>
              <p class="pricings_items_price"><span>{{d.monthPrice=='Talk to us'?'':$t("pricing.starts_at")}}</span> {{d.monthPrice}}<span>{{d.monthPrice=='Talk to us'?'':$t("pricing.mth")}}</span> </p>
              <!-- <p class="contents_paid_annually">{{d.monthPrice=='Talk to us'?'':''}}</p> -->
              <div v-if="d.ishot" class="ishot_badge"><img src="@/assets/imgs/app/feature/popular_badge.png" alt=""></div>
              <p class="pricings_items_seats">{{d.seats}}</p>
              <div class="book_a_demo" :class="[isID?'IDbook_a_demo':'']"  @click="showPopupWidget(d)">{{ $t("pricing.book_a_demo") }}</div>
              <div class="pricings_items_plans">
                <span>{{d.plans}}</span>
                <img @click="handleShowAdditioinal(d.title)" :class="{'rotates':isShowAdditionalTab[d.title]}" src="../../assets/imgs/app/feature/down.png" alt="">
              </div>
              <div class="show_additional_price" v-if="isShowAdditionalTab[d.title]">
                <!-- <p class="additional_service">Additional Service</p> -->
                <p class="additional_service_item" v-for="(dd,j) in d.planlist" :key="j">
                  <span class="additional_service_item_title">{{dd.title}}</span>
                  <img src="@/assets/imgs/pc/pricing/info.png" v-if="dd.infoContent.length" @click="open(dd.infoContent)" alt="">
                </p>
              </div>
            </div>
          </div>
          

        </el-tab-pane>
        <el-tab-pane :label="$t('pricing.yearly')" name="second">
          <div class="pricings_items" v-for="(d,i) in pricelist" :key="i">
            <div class="price_tab" :class="{'price_tab_background':d.ishot}" :style="d.topStyle">
              <div class="main_title">
                <p class="pricings_items_title">{{d.title}}</p>
                <p class="contents_item_object" :class="[isID?'IDcontents_item_object':'']">{{d.object}}</p>
              </div>
              <p class="pricings_items_price"><span>{{d.monthPrice=='Talk to us'?'':$t("pricing.starts_at")}}</span> {{d.yearPrice}}<span>{{d.yearPrice=='Talk to us'?'':$t("pricing.mth")}}</span> </p>
              <!-- <p class="contents_paid_annually">{{d.yearPrice=='Talk to us'?'':''}}</p> -->
              <div v-if="d.ishot" class="ishot_badge"><img src="@/assets/imgs/app/feature/popular_badge.png" alt=""></div>
              <p class="pricings_items_seats">{{d.seats}}</p>
              <div class="book_a_demo" :class="[isID?'IDbook_a_demo':'']"  @click="showPopupWidget(d)">{{ $t("pricing.book_a_demo") }}</div>
              <div class="pricings_items_plans">
                <span>{{d.plans}}</span>
                <img @click="handleShowAdditioinal(d.title)" :class="{'rotates':isShowAdditionalTab[d.title]}" src="../../assets/imgs/app/feature/down.png" alt="">
              </div>
              <div class="show_additional_price" v-if="isShowAdditionalTab[d.title]">
                <!-- <p class="additional_service">Additional Service</p> -->
                <p class="additional_service_item" v-for="(dd,j) in d.planlist" :key="j">
                  <span class="additional_service_item_title">{{dd.title}}</span>
                  <img src="@/assets/imgs/pc/pricing/info.png" v-if="dd.infoContent.length" @click="open(dd.infoContent)" alt="">
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      
      <!-- <div class="additional_price_tab ">
        <p class="additional_service_title">Additional Service</p>
        <p class="additional_price_item" v-for="(dd,i) in additionall_planlist" :key="i">
          <span>{{dd.title}}</span>
          <img src="@/assets/imgs/pc/pricing/info.png" v-if="dd.infoContent.length" @click="open(dd.infoContent)" alt="">
        </p>
      </div> -->
    </div>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
    <el-drawer title="Feature details" :visible.sync="drawer" direction="btt">
      <p v-for="(d,i) in datas" :key="i">{{d}}</p>
    </el-drawer>
  </div>
</template>
<script>
export default {
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "footer-nav": resolve => require(["./FooterNav.vue"], resolve),
    "menu-bar": resolve => require(["./MenuBar.vue"], resolve)
  },
  data() {
    return {
      value:"second",
      isID:false,
      drawer: false,
      pricelist: [
        {
          title: this.$t("pricing.solo"),
          monthPrice: this.$t("pricing.month_solo_price"),
          yearPrice: this.$t("pricing.year_solo_price"),
          object: this.$t("pricing.for_agent"),
          seats: this.$t("pricing.up_to_3"),
          plans: this.$t("pricing.solo_plan_includes"),
          topStyle:{borderTop: '1px solid #e6ebf2'},
          planlist: [
            {
              id: 'a1',
              title: this.$t("pricing.arcanite"),
              infoContent: []
            },
            {
              id: 'a2',
              title: this.$t("pricing.arcanite_mobile"),
              infoContent: []
            },
            {
              id: 'a3',
              title: this.$t("pricing.arcanite_connect"),
              infoContent: []
            }, {
              id: 'a4',
              title: this.$t("pricing.basic_integrations"),
              infoContent: []
            },
            {
              id: 'a5',
              title: this.$t("pricing.connect_to_marketplace_title"),
              infoContent: []
            },
            {
              id: 'a6',
              title: this.$t("pricing.up_to_3_front_end"),
              infoContent: []
            },
            {
              id: 'a7',
              title: this.$t("pricing.supports_up_to_30_projects"),
              infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: false
        },
        {
          title: this.$t("pricing.team"),
          monthPrice: this.$t("pricing.month_team_price"),
          yearPrice: this.$t("pricing.year_team_price"),
          object: this.$t("pricing.for_agent"),
          seats: this.$t("pricing.up_to_10"),
          plans: this.$t("pricing.everything_in_solo_plan_plus"),
          planlist: [
            {
              id: 'b1',
              title: this.$t("pricing.daily_reporting_feature"),
              infoContent: [
                "Based on a standard set of data metrics"
              ]
            },
            {
              id: 'b2',
              title: this.$t("pricing.data_gathering_via_short_survey_links"),
              infoContent: ["Up to 5 survey links"]
            },
            {
              id: 'b3',
              title: this.$t("pricing.project_sharing_and_affiliations"),
              infoContent: ["Requires digital handshake via Arcanite Connect"]
            }, {
              id: 'b4',
              title: this.$t("pricing.data_support"),
              infoContent: []
            },
            {
              id: 'b5',
              title: this.$t("pricing.starts_from_5_front_end_seats"),
              infoContent: []
            }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: false
        },
        {
          title: this.$t("pricing.pro"),
          monthPrice: this.$t("pricing.month_pro_price"),
          yearPrice: this.$t("pricing.year_pro_price"),
          object: this.$t("pricing.for_developer"),
          seats: this.$t("pricing.up_to_30"),
          plans: this.$t("pricing.everything_in_team_plus"),
          planlist: [
            {
              id: 'c1',
              title: this.$t("pricing.basic_integrations"),
              infoContent: []
            },
            {
              id: 'c2',
              title: this.$t("pricing.web_apis"),
              infoContent: []
            },
            {
              id: 'c3',
              title: this.$t("pricing.advanced_reporting"),
              infoContent: []
            }, {
              id: 'c4',
              title: this.$t("pricing.starts_from_30_front_end_seats"),
              infoContent: []
            },
            // {
            //   id: 'c5',
            //   title: "Up to 30 front end seats",
            //   infoContent: []
            // },
            // {
            //   id: 'c6',
            //   title: "Support up to 80 projects",
            //   infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            // }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: true
        },
        {
          title: this.$t("pricing.enterprise"),
          monthPrice: this.$t("pricing.month_enterprise_price"),
          yearPrice: this.$t("pricing.year_enterprise_price"),
          object: this.$t("pricing.for_developer"),
          // price: "Talk to us",
          seats: this.$t("pricing.starts_at_60"),
          plans: this.$t("pricing.everything_in_pro_plus"),
          topStyle:{marginBottom: '20px'},
          planlist: [
            {
              id: 'd1',
              title: this.$t("pricing.customised_admin_dashboard"),
              infoContent: []
            },
            {
              id: 'd2',
              title: this.$t("pricing.customised_permissions"),
              infoContent: []
            },
            {
              id: 'd3',
              title: this.$t("pricing.customised_sales_workflows"),
              infoContent: []
            }, {
              id: 'd4',
              title: this.$t("pricing.multi_office_support_features"),
              infoContent: []
            },
            {
              id: 'd5',
              title: this.$t("pricing.master_data_tracking_and_reporting"),
              infoContent: ["Customisation in reporting may incur longer onboarding time"]
            },
            // {
            //   id: 'd6',
            //   title: "Equimate access",
            //   infoContent: ["Subject to accreditations"]
            // },
            {
              id: 'd6',
              title: this.$t("pricing.vr_for_off_the_plan_projects"),
              infoContent: []
            },
            {
              id: 'd7',
              title: this.$t("pricing.starts_from_200_front_end_users"),
              infoContent: []
            },
            // {
            //   id: 'd8',
            //   title: "Support up to 200 projects",
            //   infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            // }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: false
        },
      ],
      datas: [],
      isShowAdditionalTab: {},
      isShow: false,
      additionall_planlist: [
        {
          id: 'a1',
          title: "Project Data Support Service (Starts at $100/month)",
          infoContent: []
        },
        {
          id: 'a2',
          title: "Apartment - once per week",
          infoContent: []
        },
        {
          id: 'a3',
          title: "Townhouse Project - twice per week",
          infoContent: []
        }, {
          id: 'a4',
          title: "House and Land Project - daily",
          infoContent: []
        },
        {
          id: 'a5',
          title: "Number of projects supported - 30 / 50 / 80 / 200",
          infoContent: ["Number of projects supported - 30 / 50 / 80 / 200"]
        },
        {
          id: 'a6',
          title: "Additional Projects ($10/project/month)",
          infoContent: []
        },
        {
          id: 'a7',
          title: "Supports up to 30 projects",
          infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
        }
      ],
    };
  },

  computed: {},
  watch: {},
  methods: {
    handleShowAdditioinal(title) {
      this.isShowAdditionalTab[title] = !this.isShowAdditionalTab[title]
      this.isShowAdditionalTab = Object.assign({}, this.isShowAdditionalTab)
    },
    showPopupWidget(d) {
      if(this.isID){
        Calendly.showPopupWidget(d.IDlink);
      }else{
        Calendly.showPopupWidget(d.link);
      }
      return false;
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    open(abc) {
      this.datas = abc
      this.drawer = true
      // let a =datas.join()
      // this.$alert(a, '', {
      //   showClose: false,
      //   lockScroll: true,
      //   showConfirmButton: false,
      //   showCancelButton: false,
      //   closeOnClickModal: true
      // });
    }
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    this.pricelist.forEach(e => {
      this.isShowAdditionalTab[e.title] = false
    })
  }
}
</script>
<style lang='scss' scoped>
.view {
  background-color: #fff;
  .blank70 {
    height: 70px;
  }
  .pricings {
    padding-top: 42px;
    background-color: #fff;
    .show_additional_price {
      .additional_service {
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #314455;
      }
      .additional_service_item {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
        img {
          margin-left: 10px;
          // width: 16px;
          height: 16px;
        }
      }
    }
    .titles {
      font-family: Poppins-Bold;
      font-size: 30px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #062440;
      margin: 0 26px 50px 26px;
    }
    .pricings_items {
      .ishot_badge {
        position: absolute;
        bottom: -16px;
        width: 124px;
        height: 36px;
        left: 50%; 
        transform: translateX(-50%); /* 50%为自身尺寸的一半 */
        -webkit-transform: translateX(-50%);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .price_tab {
        border: 1px solid #e6ebf2;
        // border-top: 0px;
        margin-left: 22px;
        margin-right: 30px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-bottom: 20px;
        position: relative;
        .main_title{
          display: flex;
          flex-direction: row;
          justify-content: space-between
        }
        .pricings_items_title {
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
        .contents_item_object{
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
          background: #F7F8FA;
          border-radius: 5px;
          padding: 5px 6px;
        }
        .IDcontents_item_object{
          font-size: 12.8px;
        }
        .pricings_items_price {
          margin-top: 10px;
          font-family: Poppins-Bold;
          font-size: 26px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
          span {
            font-family: Poppins;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.5;
            color: #67788c;
          }
        }
        .contents_paid_annually{
          text-align: left;
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          color: #67788c;
        }
        .pricings_items_seats {
          margin-top: 20px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
          padding-bottom: 10px;
          border-bottom: 1px solid #e6ebf2;
        }
        .book_a_demo {
          margin-top: 20px;
          width: 143px;
          height: 42px;
          border: solid 1px #1890ff;
          border-radius: 5px;
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 42px;
          letter-spacing: normal;
          text-align: center;
          color: #1890ff;
        }
        .IDbook_a_demo{
          width: 180px;
        }
        .pricings_items_plans {
          display: flex;
          justify-content: space-between;
          margin-top: 22px;
          span {
            font-family: Poppins-Bold;
            font-size: 14px;
            // font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #062440;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      .display_none_click {
        display: block;
      }
      .price_tab_background {
        background-color: #eef7ff;
        border: solid 1px #1890ff;
      }
    }
    .rotates {
      transform: rotate(180deg);
    }
    .additional_price_tab {
      .additional_service_title {
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #314455;
        padding-bottom: 10px;
      }
      border: 1px solid #e6ebf2;
      margin-left: 22px;
      margin-right: 30px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 20px;
      transition: all 0.5s ease;
      .additional_price_item {
        display: flex;
        align-items: center;
        span {
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
        img {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
<style lang='scss'>
.el-message-box {
  width: 300px;
}
#el-drawer__title {
  span {
    font-family: Poppins-Bold!important;
    font-size: 16px!important;
    // font-weight: 900!important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #314455;
    // border: 1px solid blue;
  }
}
.el-drawer__body {
  p {
    // border: 1px solid red;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #314455;
    text-align: left;
    padding: 0 36px 0;
  }
}


.pricings{
    // padding:0 26px;
    .el-tabs--border-card{
      border: none;
      box-shadow:none;
    }
    .el-tabs__header{
      color:#062440;
      height: 56px;
      // width:384px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom:none;
      background-color: #FFF;
      gap: 12px;
      margin-bottom:20px !important;
    }
    .el-tabs__nav-wrap .is-top{
      background: rgba(247, 248, 250, 0.6);
      // background-color: rgba(247, 248, 250, 0.6);
      padding:10px 12px;
      border-radius:4px;
    }
    .el-tabs__nav-scroll{
      // display:none;
      overflow:unset;
      width:fit-content;
    }
    .el-tabs__nav{
      gap: 12px;
    }
    .el-tabs__nav .is-top{
      display:flex;
      justify-content: center;
      align-items:center;
      min-height: 36px;
    }
    .is-top{
      // width:200px !important;
      display:flex;
      justify-content:center;
      align-items:center;
      margin:0;
    }
    .is-active{
      display:flex;
      justify-content:center;
      align-items:center;
      background-color: #1890FF !important;
      color:#FFFFFF !important;
      // margin:0 12px;
    }
    .el-tabs__item{
      color: #314455;
      min-width: 125px;
      max-width: 127px;
      min-height: 27px;
      max-height: 29px;
      font-family: 'Poppins';
      font-style: normal;
      // width: 164px;
      // height: 36px;
      border-radius: 4px;
      // padding:10px 12px;
      font-size: 14px;
      line-height: 20px;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active{
      border:none;
    }
    .el-tabs__content{
      padding:0;
    }

    // .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    //   color:#67788C !important;
    // }
}
</style>
